import { CheckboxListFacetComponentConfig, HawkSearchComponents } from '@configuration';
import { CheckboxListFacetComponentModel, CheckboxListFacetValue, FacetValue } from '@models';
import { BaseFacetComponent } from '../base-facet.component';
import defaultHtml from './checkbox-list-facet.component.hbs';
import checkboxListPartialHtml from './facet-checkbox-list.partial.hbs';

/**
 * The Checkbox List Facet component renders a checkbox for each facet and may be nested depending on the configuration in the HawkSearch admin.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-checkbox-list-facet>`.
 *
 * ## Event-Binding Attributes
 * *Note: For Event-Binding attributes common to all facet type components, see {@link Components.BaseFacetComponent}.*
 *
 * ## Handlebars Partials
 * | Name | Parameter |
 * | :- | :- |
 * | facet-checkbox-list | `Array<CheckboxListFacetValue>` |
 *
 * This partial renders a checkbox for each facet value along with any applicable actions (include, exclude, toggle).
 *
 * *Note: For more information, see {@link Models.CheckboxListFacetValue}.*
 *
 * {@embed ./facet-checkbox-list.partial.hbs}
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./checkbox-list-facet.component.hbs}
 *
 * @category Facet Types
 */
export class CheckboxListFacetComponent extends BaseFacetComponent<CheckboxListFacetComponentConfig, CheckboxListFacetComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'checkbox-list-facet';
    protected override defaultHtml = defaultHtml;

    protected override registerHelpers(): void {
        super.registerHelpers();

        this.handlebars.registerPartial('facet-checkbox-list', checkboxListPartialHtml);
    }

    protected override renderContent(): boolean {
        return !!this.data?.values?.length;
    }

    protected override getContentModel(): CheckboxListFacetComponentModel {
        const filterRegex = this.state.filter ? new RegExp(this.state.filter, 'i') : undefined;

        const filtered = (value: FacetValue): boolean => {
            if (!filterRegex) {
                return false;
            }

            return filterRegex.test(value.title);
        };

        const containsFiltered = (value: FacetValue): boolean => {
            return value.children?.some((v) => filtered(v)) || false;
        };

        const getValues = (values: Array<FacetValue> | undefined, parent: CheckboxListFacetValue | undefined): Array<CheckboxListFacetValue> | undefined => {
            return values?.map((v, i) => {
                const valueWithState = {
                    ...v,
                    visible:
                        (!parent && !filterRegex && (this.state.toggled || !this.data!.truncation || i < this.data!.truncation.threshold)) ||
                        filtered(v) ||
                        containsFiltered(v) ||
                        (!filterRegex && !!parent?.toggled),
                    hasChildren: !!v.children?.length,
                    children: undefined,
                    toggled: (v.value && v.children?.length ? this.state.values?.[v.value!]?.toggled ?? false : false) || containsFiltered(v)
                };

                (valueWithState as any).children = getValues(v.children, valueWithState);

                return valueWithState;
            });
        };

        return {
            displayCount: this.data!.displayCount ?? true,
            expanded: this.state.toggled ?? false,
            maxHeight: this.data!.scrolling
                ? this.data!.values!.length! >= this.data!.scrolling.threshold
                    ? this.data!.scrolling.height ?? 200
                    : undefined
                : undefined,
            showToggle: !!this.data!.truncation && (this.data!.values?.length ?? 0) > this.data!.truncation.threshold && !this.state.filter,
            values: getValues(this.data!.values, undefined) ?? [],
            excludeEnabled: this.configuration?.excludeEnabled ?? false,
            strings: {
                collapse: this.configuration?.strings?.collapse ?? 'Collapse',
                exclude: this.configuration?.strings?.exclude ?? 'Exclude',
                expand: this.configuration?.strings?.expand ?? 'Expand',
                include: this.configuration?.strings?.include ?? 'Include',
                toggle: this.state.toggled ? this.configuration?.strings?.showFewer ?? 'Show fewer' : this.configuration?.strings?.showMore ?? 'Show more'
            }
        };
    }
}
